export default {
    subjects: [
        {
            name: "Biology",
            short: "Biology",
            acronym: "Biology",
            image: "biology.png",
            root: "subject",
            route: "/user/subject/Biology",
        },
        {
            name: "Chemistry",
            short: "Chemistry",
            acronym: "Chemistry",
            image: "chemistry.jpg",
            root: "subject",
            route: "/user/subject/Chemistry",
        },
        {
            name: "Environmental Science",
            short: "Envi Sci",
            acronym: "Environmental Science",
            image: "en-sci.jpg",
            root: "subject",
            route: "/user/subject/Environmental Science",
        },
        {
            name: "Forensic Science",
            short: "Forensic Sci",
            acronym: "Forensic Science",
            image: "for-sci.jpeg",
            root: "subject",
            route: "/user/subject/Forensic Science",
        },
        {
            name: "General Science",
            short: "Gen Sci",
            acronym: "General Science",
            image: "gen-sci.jpeg",
            root: "subject",
            route: "/user/subject/General Science",
        },
        {
            name: "Geological Science",
            short: "Geo Sci",
            acronym: "Geological Science",
            image: "geo-sci.jpg",
            root: "subject",
            route: "/user/subject/Geological Science",
        },
        {
            name: "Marine Science",
            short: "Marine Sci",
            acronym: "Marine Science",
            image: "mar-sci.jpg",
            root: "subject",
            route: "/user/subject/Marine Science",
        },
        {
            name: "Material Science",
            short: "Mat Sci",
            acronym: "Material Science",
            image: "mat-sci.jpg",
            root: "subject",
            route: "/user/subject/Material Science",
        },
        {
            name: "Mathematics",
            short: "Math",
            acronym: "Mathematics",
            image: "math.jpeg",
            root: "subject",
            route: "/user/subject/Mathematics",
        },
        {
            name: "Molecular Biology & Biotechnology",
            short: "M.B.B.",
            acronym: "Molecular Biology & Biotechnology",
            image: "mbb.jpeg",
            root: "subject",
            route: "/user/subject/Molecular Biology & Biotechnology",
        },
        
        {
            name: "Physics",
            short: "Physics",
            acronym: "Physics",
            image: "physics.jpeg",
            root: "subject",
            route: "/user/subject/Physics",
        },
     
    ]
}