<template lang="pug">
div
  logout(v-model="showLogout", @click="showLogout = true")
  v-app-bar.pl-lg-6.pl-1.pr-5.pr-md-1(
        app,
        flat,
        height="55"
        outlined
        hide-on-scroll
        scroll-threshold="30"
      )
        v-row(justify="start", align="center")
          v-col.mt-lg-1.mb-lg-1.mt-2(md="3", sm="4", cols="10")
            .d-inline.ml-md-n2.ml-n4
              v-icon.mb-2.rounded-lg.head-text.hidden-lg-and-up.pl-1(
                @click.stop="drawer = !drawer"
              ) mdi-menu
            .d-inline.pt-3.pl-1.pr-n4.pl-md-2
              h4.mt-3.font-weight-bold.grey--text.d-inline e
              h4.font-weight-bold.head-text.d-inline.text Book
              h4.ml-1.font-weight-light.d-inline.text Hub

          v-col.mx-sm-auto.ml-auto.text-end(
            md="7",
            sm="7",
            cols="3",
            v-if="$vuetify.breakpoint.smAndUp"
          )
            v-btn.mr-md-n10.mr-sm-n5.mr-0.mt-n1(@click="search()" depressed color="tertiary" rounded) <v-icon left> mdi-magnify </v-icon> Search

          v-col.d-flex.justify-content-end.mt-2.ml-auto(md="2", sm="1", cols="1")
            v-btn.ml-auto.mr-3(
              v-if="$vuetify.breakpoint.xs",
              @click="search()",
              depressed,
              fab
              x-small
            ).avatar-search
              v-icon mdi-magnify
            v-menu(transition="slide-x-transition", offset-y, bottom, right)
              template(v-slot:activator="{ on, attrs }")
                v-btn.mb-3.mr-n6.mr-sm-n5(
                  v-bind="attrs",
                  v-on="on",
                  depressed,
                  v-if="$vuetify.breakpoint.smAndDown",
                  fab,
                  color="transparent",
                  x-small
                )
                  v-avatar.text-center(size="32", v-if="!$store.state.userToken.picture")
                    v-icon(large, color="grey") mdi-account-circle
                  v-avatar.justify-content-end(size="32", v-else)
                    img(:src="$store.state.userToken.picture")
                v-btn.mb-3.ml-auto.avatar-text.mr-1(
                  v-bind="attrs",
                  v-on="on",
                  dark,
                  depressed,
                  v-else
                  rounded
                )
                  v-avatar.text-center.mr-2(size="24", v-if="!$store.state.userToken.picture")
                    v-icon(color="grey") mdi-account-circle
                  v-avatar.justify-content-center.mr-2(size="24", v-else)
                    img(:src="$store.state.userToken.picture")
                  span User

              //- Account Dropdown
              
              v-list
                v-list-item(
                  dense,
                  :to="{ path: '/user/profile' }",
                  replace
                ).no-design
                  //- Profile
                  v-list-item-icon
                    v-icon.ml-1 mdi-account
                  v-list-item-title.no-design.ml-n4(dense) Profile

                v-list-item(
                  dense,
                  @click.stop="showLogout = true"
                )
                  //- Survey Form Logout
                  v-list-item-icon
                    v-icon.ml-1 mdi-logout
                  v-list-item-title.ml-n4(dense) Logout
        //- Progress bar
        //- v-progress-linear(
        //- :active="loading"
        //- :indeterminate="loading"
        //- absolute
        //- bottom
        //- color="rgba(1, 0, 115, .3)"
        //- height="3"
        //- ).pl-n10
        //- Sidebar
  sidebar(v-model="drawer")

  //- the content
  .mx-lg-10.mx-md-13.mx-2(style="min-height: 60vh")
    transition
      keep-alive
        router-view.mt-2(:key="$route.path")
        

  //- footer
  v-footer.pb-3.mt-5(color="transparent", padless) 
    v-card.text-center(flat, tile, color="transparent", width="1200")
      v-divider
      v-btn.mx-4(v-for="icon in icons", :key="icon.name", icon dark)
        <a :href="icon.url" :target="icon.target"><v-icon size="24px">{{ icon.name }}</v-icon></a>
      h6.mt-2.text-muted CS Libraries eBook Hub — {{ new Date().getFullYear() }} 
      h6.mt-2.text-muted Funded by the <em> College of Science, University of the Philippines Diliman </em>

</template>

<script>
import Logout from "./Logout.vue";
import sidebar from "./Sidebar.vue";

export default {
  components: { Logout, sidebar },
  data() {
    return {
      drawer: false,
      dialog: false,
      showLogout: false,
      users: [{ title: "Profile", route: "/user/profile" }],

      value: ["title", "author", "year"],
      icons: [
        {
          name: "mdi-facebook",
          url: "https://www.facebook.com/UPCSLibrary/",
          target: "_blank",
        },
        {
          name: "mdi-twitter",
          url: "https://twitter.com/updcslib",
          target: "_blank",
        },
        {
          name: "mdi-web",
          url: "http://www.cslib.upd.edu.ph/index.php",
          target: "_blank",
        },
      ],
    };
  },
  methods: {
    search() {
      this.$router.push("/results/search");
    },
  },
};
</script>

<style scoped>
.bg-primary {
  background-color: #f3f3f3 !important;
}

.avatar-text {
  background: rgba(53, 51, 143, 0.9) !important;
  color: white;
}

.avatar-search {
  background: rgba(53, 51, 143, 0.9) !important;
  color: white;
}

.head-text {
  color: #010073;
}

.no-design {
  text-decoration: none !important;
  color: black;
}

.no-design :hover {
  text-decoration: none !important;
}
</style>
