<template lang="pug">
  //- navigation drawer
  v-navigation-drawer(
    app,
    width="240",
    :permanent="$vuetify.breakpoint.lgAndUp && $route.name != 'Search'"
    v-model="showSidebar"
    :temporary="$route.name == 'Search'"
  )
    //- CS LIB title
    v-row.ml-1.mt-n1
      v-col(cols="3")
        img(src="../views/index.png", alt="cs lib logo", width="40px")
      v-col.ml-n2(cols="9") 
        .caption College of Science
        h5.font-weight-black.head-text.mt-n1 LIBRARIES
    .mt-n7.mb-n3.mx-3
      v-divider

    //- main list
    v-list(dense, nav)
      v-list-item.no-line(router, @click="link()" v-if="$store.state.userToken.type=='admin' && $store.state.userToken.admin == true")
        v-list-item-icon
          v-icon mdi-chart-line
        v-list-item-content.ml-n2
          v-list-item-title Admin Module
      v-list-item.no-line(
        v-for="nav in navItems",
        :key="nav.title",
        router,
        :to="nav.route",
        active-class="white--text primary"
      )
        v-list-item-icon
          v-icon {{ nav.icon }}
        v-list-item-content.ml-n2
          v-list-item-title {{ nav.title }}
      v-list-item.no-line(router to="/user/shelf" active-class="white--text primary")
          v-list-item-icon
            v-icon mdi-bookshelf
          v-list-item-content.ml-n2
            v-list-item-title Shelf
          v-badge(color="primary" inline :content="bookmarks" v-if="$route.name!=='Shelf'")
          v-badge(color="tertiary" inline :content="bookmarks" v-else style="color:black")
    .mt-n3.mb-n3.mx-3
      v-divider
    //- expanded list
    v-list.mt-n3(dense, nav)
      v-list-group(
        v-for="item in items",
        :key="item.title",
        v-model="item.active",
        :prepend-icon="item.action",
        no-action
        active-class="{background-color: red; color : white !important;}"
      )
        template(v-slot:activator)
          v-list-item-content.ml-n2.mr-n5
            v-list-item-title(v-text="item.title")
        v-list-item.ml-n12(
          v-for="child in item.items",
          :key="child.acronym",
          router,
          :to="child.route"
        )
          v-icon.mr-2(x-small) mdi-atom-variant
          v-list-item-content.mr-n5
            v-list-item-title(v-text="child.acronym")
      v-list-group(
        v-model="course",
        prepend-icon="mdi-newspaper-variant",
        no-action
      )
        template(v-slot:activator)
          v-list-item-content.ml-n2.mr-n5
            v-list-item-title Course References
        v-list-item.ml-n12(
          v-for="child in institutes"
          :key="child.acronym"
          router
          :to="{name: 'CourseReferences', params: { slug: child.name }}"
        )
          v-icon.mr-2(x-small) mdi-atom-variant
          v-list-item-content.mr-n5
            v-list-item-title(v-text="child.acronym")   
</template>
<script>
import syllabi from "@/syllabi.js";
import subjects from "@/subjects.js";
export default {
  name: "Sidebar",
  props: ["value"],
  computed: {
    bookmarks: {
      get() {
        const book = this.$store.state.userInfo.bookmarks;
        if (book) {
          return book.length.toString();
        }
        return ".";
      },
      set(payload) {
        this.$store.commit("updateBookmarks", payload);
      },
    },
    showSidebar: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
  },
  data() {
    return {
      course: "",
      institutes: syllabi.institutes,
      navItems: [
        { title: "Home", icon: "mdi-home", route: "/user/home" },
        { title: "Profile", icon: "mdi-account", route: "/user/profile" },
      ],
      items: [
        {
          action: "mdi-microscope",
          items: subjects.subjects,
          title: "Subject",
        },
        {
          action: "mdi-folder-network",
          items: syllabi.institutes,
          title: "Syllabi References",
        },
      ],
    };
  },
  methods: {
    link() {
      this.$router.replace("/admin");
    },
  },
};
</script>
<style>
.v-list-item.no-line {
  text-decoration: none !important;
}
.v-badge__badge.tertiary {
  color: #010073;
}
</style>
