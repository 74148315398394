export default {
  institutes: [
    {
      id: 1,
      name: "Institute of Biology",
      acronym: "IB",
      image: "ib.png",
      root: "syllabi",
      route: "/user/syllabi/Institute of Biology",
      color: "#005918",
    },
    {
      id: 2,
      name: "Institute of Chemistry",
      acronym: "IChem",
      image: "ic.png",
      root: "syllabi",
      route: "/user/syllabi/Institute of Chemistry",
      color: "#490163",
    },
    {
      id: 3,
      name: "Institute of Environmental Science and Meteorology",
      acronym: "IESM",
      image: "iesm.png",
      root: "syllabi",
      route: "/user/syllabi/Institute of Environmental Science and Meteorology",
      color: "#45ab1d",
    },
    {
      id: 4,
      name: "Institute of Mathematics",
      acronym: "IM",
      image: "imath.png",
      root: "syllabi",
      route: "/user/syllabi/Institute of Mathematics",
      color: "#8c0067",
    },
    {
      id: 5,
      name: "Materials Science and Engineering Program",
      acronym: "MSEP",
      image: "msep.png",
      root: "syllabi",
      route: "/user/syllabi/Materials Science and Engineering Program",
      color: "#495753",
    },
    {
      id: 6,
      name: "Marine Science Institute",
      acronym: "MSI",
      image: "msi.png",
      root: "syllabi",
      route: "/user/syllabi/Marine Science Institute",
      color: "#002d59",
    },
    {
      id: 7,
      name: "National Institute of Geological Sciences",
      acronym: "NIGS",
      image: "nigs.png",
      root: "syllabi",
      route: "/user/syllabi/National Institute of Geological Sciences",
      color: "#592100",
    },
    {
      id: 8,
      name: "National Institute of Molecular Biology and Biotechnology",
      acronym: "NIMBB",
      image: "nimmb.png",
      root: "syllabi",
      route:
        "/user/syllabi/National Institute of Molecular Biology and Biotechnology",
      color: "#006e50",
    },
    {
      id: 9,
      name: "National Institute of Physics",
      acronym: "NIP",
      image: "nip.png",
      root: "syllabi",
      route: "/user/syllabi/National Institute of Physics",
      color: "#b00202",
    },
  ],
};
